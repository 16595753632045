import React from "react";
import Layout from "../components/layout";

const ThankYou = () => {

    return (
        <Layout>
            <div
                className="thank-you-page wrapper w-full lg:w-1/2 pt-8">
                <div className="container" style={{minHeight: 400}}>
                    <h3 className="text-center">
                        Thank you for making payment!
                    </h3>
                </div>
            </div>
        </Layout>
    );
};
export default ThankYou;
